<template>
  <div class="createAlias">
    <form v-on:submit.prevent>
      <h1>Create a new alias:</h1>
      <label for="created_for">who is it for?</label>
      <input v-model="created_for" type="text" id="created_for" name="created_for" value="">
      <button @click='createAlias' class='button'>Create!</button>
    </form>
    <div v-if="new_alias_address !== ''" class="newAliasAddress--container">
      <span>New email Alias:</span>
      <input type="text" :value="new_alias_address" id="new_alias_address_input">
      <button @click="copyToClipboard()">copy</button>
    </div>
    <span v-else-if="loading">Creating a new email alias for you...</span>
  </div>
</template>

<script>
import { API } from 'aws-amplify';

export default {
  name: 'CreateAlias',
  data() {
    return {
      created_for: "",
      new_alias_address: "",
      loading: false
    }
  },
  methods: {
    async createAlias() {
      this.loading = true;
      API.post('aliasRecordsApi', `/aliasRecords`, {
        body: { created_for: this.created_for },
      }).then(result => {
        console.log(result);
        this.$emit('alias-created', result.Item);
        this.new_alias_address = result.Item.alias_email;
      }).catch(err => {
        console.log(err);
      }).finally(() => {this.loading = false});
    },

    copyToClipboard: function() {
      var copyText = document.getElementById("new_alias_address_input");
      copyText.select();
      copyText.setSelectionRange(0, this.new_alias_address.length)
      document.execCommand("copy");
    }
  },
}
</script>

<style>
input {
  margin: 1em;
  padding: 0.5em;
  width: 16.5em;
}
</style>
