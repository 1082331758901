/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "aliasRecordsTable-prod",
            "region": "us-east-1"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-1:51410b4c-7690-41f3-a532-4e075940bde4",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_yWc8OeK5V",
    "aws_user_pools_web_client_id": "3hii46tsd2h1nqk60n5p5kuih3",
    "oauth": {},
    "aws_cloud_logic_custom": [
        {
            "name": "aliasRecordsApi",
            "endpoint": "https://k4epmd5ddg.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        }
    ]
};


export default awsmobile;
