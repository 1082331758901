<template>
  <div class="AliasList">
    <div v-if="alias_record_list && alias_record_list.length > 0" class="AliasList--container">
      <h1>Your existing email aliases:</h1>
      <div class="AliasList--filter">
        <span>Find:</span>
        <input type="text" v-model="alias_filter" id="alias_record_list_input">
      </div>
      <table id="aliases_table">
        <tr>
          <th>Email alias</th>
          <th>Created for</th>
          <th>Created at</th>
          <th>Created active</th>
        </tr>
        <tr v-for="alias_record in filtered_alias_record_list" :key="alias_record.alias_email">
          <td class="my_alias_record--alias_email">{{ alias_record.alias_email }}</td>
          <td class="my_alias_record--created_for">{{ alias_record.created_for }}</td>
          <td class="my_alias_record--created_date">{{ new Date(alias_record.created_date).toLocaleString() }}</td>
          <td
            @click="toggleRecord(alias_record.alias_email)"
            class="my_alias_record--active">{{ alias_record.active ? 'active' : 'not active' }}</td>
        </tr>
      </table>
    </div>
    <div v-else-if="alias_record_list && alias_record_list.length === 0">
      You have no email aliases, you can create one below
    </div>
    <div v-else>loading your email aliases...</div>
  </div>
</template>

<script>
import { API } from 'aws-amplify';
// import {AuthState, onAuthUIStateChange} from "@aws-amplify/ui-components";

export default {
  name: 'AliasList',
  created() {
    console.log("this is AliasList -- created");
    // this.unsubscribeAuth = onAuthUIStateChange((nextAuthState, authData) => {
    //   console.log("onAuthUIStateChange", nextAuthState);
    //   if (nextAuthState === AuthState.SignedIn) {
    //     console.log("user successfully signed in!");
    //     console.log("user data: ", authData);
    //     API.get('aliasRecordsApi', `/aliasRecords/my`).then(result => {
    //       console.log(result);
    //       this.$emit('alias-created', result.data);
    //       this.alias_record_list = result;
    //     }).catch(err => {
    //       console.log(err);
    //     });
    //   }
    //   if (!authData) {
    //     console.log("user is not signed in...");
    //   }
    // });
  },
  mounted() {
    console.log("mounted!!!");
    API.get('aliasRecordsApi', `/aliasRecords/my`).then(result => {
      console.log(result);
      this.$emit('alias-created', result.data);
      this.alias_record_list = result;
    }).catch(err => {
      console.log(err);
    });
  },
  data() {
    return {
      alias_record_list: undefined,
      alias_filter: ""
    }
  },
  computed: {
    filtered_alias_record_list: function () {
      if(this.alias_record_list === undefined) {
        return this.alias_record_list;
      }
      return this.alias_record_list.filter(record => {
        return record.alias_email.includes(this.alias_filter);
      }).sort((recordA, recordB) => recordB.created_date - recordA.created_date);
    }
  },
  methods: {
    toggleRecord (alias_email) {
      let record = this.alias_record_list.find(record => record.alias_email === alias_email);
      console.log("record toggle");
      API.put('aliasRecordsApi', `/aliasRecords/toggle/${alias_email}`, {
        body: {active: !record.active}
      }).then(result => {
        console.log(result);
        record.active = !record.active;
      }).catch(err => {
        console.log(err);
      });
    }
  },
  unmounted() {
    console.log("unmounted!!!");
  },
  // beforeDestroy() {
  //   console.log("destroying!");
  //   this.unsubscribeAuth();
  // },
}
</script>

<style>
table {
  display: inline-block;
  max-width: 100%;
  overflow: auto;
}
table, th, td {
  border: 1px dotted black;
  border-collapse: collapse;
}
th, td {
  padding: 0.5em;
  text-align: left;
  overflow: auto;
  max-width: 20vw;
}
.my_alias_record--active{
  cursor: pointer;
  font-weight: bold;
  color: blue;
}
</style>
