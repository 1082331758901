<template>
  <amplify-authenticator username-alias="email">
        <amplify-sign-up
          slot="sign-up"
          username-alias="email"
          :form-fields.prop="formFields"
        ></amplify-sign-up>
    <div id="app">
      <img alt="Vue logo" src="./assets/logo.png">
      <AliasList v-if="authState === 'signedin' && user"/>
      <CreateAlias/>
    </div>
    <amplify-sign-out></amplify-sign-out>
  </amplify-authenticator>
</template>

<script>
import { onAuthUIStateChange } from '@aws-amplify/ui-components'
import CreateAlias from './components/CreateAlias.vue'
import AliasList from './components/AliasList.vue'

export default {
  name: 'App',
  created() {
    this.unsubscribeAuth = onAuthUIStateChange((authState, authData) => {
      console.log("onAuthUIStateChange", authState);
      this.authState = authState;
      this.user = authData;
    })
  },
  data() {
    return {
      user: undefined,
      authState: undefined,
      formFields: [
        { type: 'email', required: true, },
        { type: 'password', required: true, },
      ]
    }
  },

  components: {
    CreateAlias,
    AliasList
  },

  beforeDestroy() {
    this.unsubscribeAuth();
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

amplify-sign-out {
  position: fixed;
  bottom: 0;
  right: 0;
  margin: 2em;
}
</style>
